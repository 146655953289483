import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["search", "item"];
  static values = {
    searchSrc: String,
  };

  initialize() {
    this.init = this.init.bind(this);
  }

  connect() {
    document.addEventListener("turbo:load", this.init);
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.init);
    this.selectChoices.destroy();
  }

  async init() {
    this.currentCategoryEl = document.querySelector(
      ".providers-engine-categories-item"
    );

    this.selectChoices = new Choices(this.searchTarget, {
      allowHTML: false,
      placeholder: true,
      placeholderValue: "Buscar servicios",
      searchPlaceholderValue: "Ej: Plomería",
      itemSelectText: "clic para elegir",
      loadingText: "Buscando servicios...",
    });

    this.selectChoices.setChoices(async () => {
      const response = await get(`/categories/hogar/services.json`);
      const services = await response.json;

      return services.map((service) => {
        return { label: service.name, value: service.slug };
      });
    });

    this.selectChoices.passedElement.element.addEventListener(
      "change",
      async (e) => {
        if (!e.detail.value) return;
        const frame = document.getElementById("providers-engine-results");
        frame.src = `service_subscribers?service_id=${e.detail.value}`;
      }
    );
  }

  async setCategory(e) {
    try {
      const frame = document.getElementById("providers-engine-results");
      frame.src = `service_subscribers?category_id=${e.params.id}`;
    } catch (error) {}

    this.selectChoices.setChoices(
      async () => {
        const response = await get(`/categories/${e.params.id}/services.json`);
        const services = await response.json;
        return services.map((service) => {
          return { label: service.name, value: service.slug };
        });
      },
      "value",
      "label",
      true
    );

    this.currentCategoryEl.classList.remove(
      "providers-engine-categories-item--active"
    );
    this.currentCategoryEl = e.target;
    e.target.classList.add("providers-engine-categories-item--active");
  }

  toggleContact(e) {
    if (this.currentContactEl) {
      this.currentContactEl.classList.remove(
        "providers-engine-item--contactable-mobile"
      );
    }

    if (this.currentContactEl == this.itemTargets[e.params.index]) {
      this.currentContactEl = null;
      return;
    }

    this.currentContactEl = this.itemTargets[e.params.index];
    this.currentContactEl.classList.add(
      "providers-engine-item--contactable-mobile"
    );
  }
}
